var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-tree" }, [
    _c(
      "div",
      { staticClass: "filter" },
      [
        _c("el-input", {
          attrs: { placeholder: _vm.$t("label.inputKeywordToFilter") },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tree" },
      [
        _c("el-tree", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "GeneralSelectTree",
          attrs: {
            "node-key": _vm.treeKey,
            data: _vm.treeData,
            props: _vm.defaultProps,
            "default-expand-all": _vm.expandAll,
            "check-strictly": !_vm.isMultiple,
            "highlight-current": true,
            "check-on-click-node": true,
            "filter-node-method": _vm.filterNode,
            "show-checkbox": _vm.isMultiple,
            "default-expanded-keys": _vm.valueArr,
            "default-checked-keys": _vm.treeCheckedKeys,
          },
          on: { "node-click": _vm.handleNodeClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }