import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralSelectTree from '@/components/GeneralSelectTree';
import waves from '@/directive/waves'; // waves directive
import { getLocalTimestamp, deepClone } from '@/utils';
import { getToken } from '@/utils/auth';
export default {
  name: 'Index',
  components: {
    GeneralSelectTree: GeneralSelectTree
  },
  directives: {
    waves: waves
  },
  props: {
    columns: {
      type: Array,
      default: []
    },
    editForm: {
      type: Object,
      default: {}
    },
    rules: {
      type: Object,
      default: {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      currentTreeItem: null,
      treeKey: 'value',
      treeLabel: 'label',
      treeUrl: '',
      isMultiple: false,
      treeCheckedKeys: [],
      treeSelectDialogVisible: false,
      treeSelectLeafOnly: false,
      uploadUrl: process.env.VUE_APP_BASE_API + '/image-upload',
      imgUrl: process.env.VUE_APP_BASE_API + 'image/',
      myHeaders: {
        'X-Auth-Token': getToken()
      },
      dialogUploadImageUrl: null,
      dialogUploadImageVisible: false,
      loading: false,
      cascaderUseSelectColumns: [],
      cascaderBaseKey: 10000
    };
  },
  computed: {
    currentColumns: function currentColumns() {
      return this.columns.filter(function (item) {
        return (item.flag & CTP.FLAG_DETAIL_HIDE) == 0;
      });
    },
    codes: function codes() {
      return this.$store.getters.codeDataDictionary.codes;
    }
  },
  watch: {
    '$route': function $route(to, from) {
      //this.initData()
    },
    editForm: {
      handler: function handler(val, oldVal) {
        if (isNull(this.editForm)) {
          return;
        }
        var _iterator = _createForOfIteratorHelper(this.cascaderUseSelectColumns),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var col = _step.value;
            //console.log('handler:'+col.code)
            // console.log(val[col.code])
            // console.log(oldVal[col.code])
            if (val[col.code] && !oldVal[col.code]) {
              var arr = val[col.code];
              this.editForm[col.code] = arr[0] + '_' + arr[1];
              this.getSelectOptions(null, col);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    //console.log('created')
    this.initData();
  },
  methods: {
    initData: function initData() {
      var recCreatorIndex = 0;
      var hasRecCreator = false;
      var _iterator2 = _createForOfIteratorHelper(this.currentColumns),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var col = _step2.value;
          if (col.code == 'c_rec_creator') {
            hasRecCreator = true;
          } else if (!hasRecCreator) {
            recCreatorIndex++;
          }
          if (this.readOnly) {
            col.editable = false;
          }
          if (col.cascaderUseSelect) {
            this.cascaderUseSelectColumns.push(col);
            this.getSelectOptions(null, col);
          }
        }
        //console.log(recCreatorIndex)
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      if (recCreatorIndex > 0 && recCreatorIndex % 2 == 1) {
        var o = {
          code: '',
          label: '',
          type: 'input',
          editable: false
        };
        this.currentColumns.splice(recCreatorIndex, 0, o);
      }
      //console.log(this.columns)
    },
    openTreeSelectDialog: function openTreeSelectDialog(item) {
      var _this = this;
      // console.log(item)
      this.currentTreeItem = item;
      // this.treeKey = item.optionKey
      // this.treeLabel = item.optionLabel
      this.treeUrl = item.treeUrl;
      this.isMultiple = item.type === 'treeMultiSelect';
      this.treeSelectDialogVisible = true;
      if (item.treeSelectLeafOnly) {
        this.treeSelectLeafOnly = true;
      } else {
        this.treeSelectLeafOnly = false;
      }
      //console.log(this.treeSelectLeafOnly)
      // console.log(this.treeUrl)
      setTimeout(function () {
        _this.$refs.treeSelect.getTreeData();
      }, 100);
      this.treeCheckedKeys = this.editForm[this.currentTreeItem.code];
    },
    closeTreeSelectDialog: function closeTreeSelectDialog() {
      this.treeSelectDialogVisible = false;
    },
    handleTreeDataLoaded: function handleTreeDataLoaded(treeData) {
      // this.treeCheckedKeys = this.editForm[this.currentTreeItem.code]
      // console.log(this.treeCheckedKeys)
    },
    handleTreeNodeClick: function handleTreeNodeClick(value) {
      // console.log(value)
    },
    handleConfirm: function handleConfirm() {
      //console.log(this.$refs.treeSelect.$refs.GeneralSelectTree.getCheckedKeys())
      var checkedNode = this.$refs.treeSelect.$refs.GeneralSelectTree.getCheckedNodes();
      //只允许选择叶子
      if (this.treeSelectLeafOnly && checkedNode && checkedNode[0] && checkedNode[0].children && checkedNode[0].children.length > 0) {
        return false;
      }
      this.treeCheckedKeys = this.$refs.treeSelect.$refs.GeneralSelectTree.getCheckedKeys();
      if (!this.isMultiple) {
        if (this.treeCheckedKeys.length > 1) this.treeCheckedKeys = this.treeCheckedKeys.slice(0, 1);
      }
      //console.log(this.treeCheckedKeys)
      if (this.treeCheckedKeys.length > 0) {
        this.editForm[this.currentTreeItem.code] = this.treeCheckedKeys;
        this.treeSelectDialogVisible = false;
      }
    },
    handleClear: function handleClear() {
      this.$refs.treeSelect.$refs.GeneralSelectTree.setCheckedKeys([]);
      this.editForm[this.currentTreeItem.code] = [];
      this.treeSelectDialogVisible = false;
    },
    handleCancel: function handleCancel() {
      this.treeSelectDialogVisible = false;
    },
    getLocalTime: function getLocalTime(timestamp) {
      return getLocalTimestamp(timestamp);
    },
    beforeUpload: function beforeUpload(file) {
      var msgSize = '上传单张图片大小不能超过1M';
      var islimit = file.size / 1024 < 1024;
      if (!islimit) {
        this.$message.error(msgSize);
        return false;
      }
    },
    onUploadSuccess: function onUploadSuccess(item) {
      var editForm = this.editForm;
      return function (response, file, fileList) {
        var id = Number(response.data);
        file.imageId = id;
        if (!editForm[item.code]) {
          editForm[item.code] = [];
        }
        editForm[item.code].push(id);
      };
    },
    onUploadExceed: function onUploadExceed(item) {
      return function (file, fileList) {
        var limit = item.width ? item.width : 1;
        var msg = null;
        if (limit <= 1) {
          msg = this.$t('rules.common.uploadExceed_1');
        } else {
          msg = this.$t('rules.common.uploadExceed_2');
        }
        this.$message.warning(msg.format(item.label, limit));
      };
    },
    onUploadRemove: function onUploadRemove(item) {
      var editForm = this.editForm;
      return function (file, fileList) {
        var result = null;
        if (file.response) {
          result = file.response.data;
        }
        var imageId = file.imageId || result || file.url.replace(/.*\//img, '');
        if (imageId && this.isNumber(imageId)) {
          file.imageId = imageId;
        }
        if (!editForm[item.code]) {
          editForm[item.code] = '';
        }
        if (!Array.isArray(editForm[item.code])) {
          editForm[item.code] = editForm[item.code].split(',');
        }
        editForm[item.code].remove(file.imageId);
      };
    },
    getUploadImageFileList: function getUploadImageFileList(item) {
      if (isNull(this.editForm[item.code])) {
        return [];
      }
      try {
        if (!Array.isArray(this.editForm[item.code])) {
          this.editForm[item.code] = JSON.parse(this.editForm[item.code]);
        }
      } catch (e) {}
      if (!Array.isArray(this.editForm[item.code])) {
        this.editForm[item.code] = (this.editForm[item.code] + '').split(",");
      }
      var list = [];
      this.editForm[item.code].forEach(function (image, index) {
        list.push({
          url: getImage(image),
          imageId: image,
          name: item.label + (index + 1)
        });
      });
      return list;
    },
    onUploadError: function onUploadError(err, file, fileList) {},
    onUploadImagePreview: function onUploadImagePreview(file) {
      this.dialogUploadImageUrl = file.url;
      this.dialogUploadImageVisible = true;
    },
    inputTrim: function inputTrim(o, s) {
      if (s != undefined && s != null) {
        this.editForm[o] = s.replace(/(^\s*)|(\s*$)/g, "");
      }
    },
    remoteMethod: function remoteMethod(query, item) {
      this.getSelectOptions(query, item);
    },
    getSelectOptions: function getSelectOptions(query, item) {
      //console.log('getSelectOptions, query :'+query+', item :'+ item.code)
      var maxLimit = 100;
      var count = 0;
      var codeDD = this.codes.filter(function (n) {
        return n.propId === item.propId;
      });
      var itemOptionsClone = deepClone(codeDD[0].data);
      var num = 0;
      var options = [];
      //console.log(this.editForm[item.code])
      var _iterator3 = _createForOfIteratorHelper(itemOptionsClone),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var x = _step3.value;
          var _iterator4 = _createForOfIteratorHelper(x.children),
            _step4;
          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var y = _step4.value;
              //已选中的在最上面回显
              if (this.editForm && this.editForm[item.code] && this.editForm[item.code].indexOf(x.value + '_' + y.value) >= 0) {
                count++;
                options.unshift({
                  value: x.value + '_' + y.value,
                  label: x.label + ' / ' + y.label
                });
                num++;
              } else if (query == null || x.label.toLowerCase().indexOf(query.toLowerCase()) >= 0 || y.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                count++;
                if (num < maxLimit) {
                  options.push({
                    value: x.value + '_' + y.value,
                    label: x.label + ' / ' + y.label
                  });
                  num++;
                }
              }
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
        }
        //console.log("count="+count+", num="+num)
        //console.log(options)
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      if (num >= maxLimit) {
        options.unshift({
          value: '$$$$',
          label: '以下只显示了前' + num + '个，还有' + (count - num) + '个选项未显示，请通过搜索缩小范围',
          disabled: true
        });
      }
      item.options = options;
      //console.log(options)
    },
    increaseCascaderBaseKey: function increaseCascaderBaseKey() {
      this.cascaderBaseKey = this.cascaderBaseKey + 10000;
    }
  }
};